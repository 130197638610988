body {
	margin: 0
}

h1, h2, h3 {
	font-family: Barlow, sans-serif;
}

p, input {
	font-family: "Nunito Sans", sans-serif;
	font-weight: lighter;
}

span.p-highlight {
	color: rgb(255, 110, 66);
	font-weight: normal;
}

.outer-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.section-outer {
	display: flex;
	justify-content: center;
}
.section-inner {
	width: 50%;
	min-width: 250px;
}

.header-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgb(246, 249, 252);
}

.header-bar h1 {
	font-size: 18px;
}

.section-1 {
	padding-top: 5%;
	padding-bottom: 5%;
	color: rgb(246, 249, 252);
}

.section-1 h1 {
	font-size: 35px;
}

.section-1 p {
	font-size: 18px;
}

#section-1-body-flex {
	display: flex;
	align-items: center;
}

#section-1-body-flex > p {
}

#section-1-body-flex > img {
	height: 150px;
	margin-left: 5vw;
}

.section-2 {
	padding-top: 3%;
}

.section-2 h3 {
	text-align: center;
}

.section-2 form {
	text-align: center;
}

.section-2 input {
	font-size: 16px;
	height: 40px;
}

.section-2 input.text-input {
	width: 200px;
	padding-left: 10px;
}

.section-2 input.submit {
	background-color: rgb(255, 110, 66);
	border-radius: 5px;
	color: white;
	padding-left: 15px;
	padding-right: 15px;
	border: none;
	cursor: pointer;
}

.section-2 input.submit:hover {
	background-color: #c65634;
}

.section-2 p {
	text-align: center;
	font-size: 18px;
}

.section-1, .header-bar-outer {
	background-color: #575766;
}
.section-2 {
	background-color: #303139;
	color: rgb(246, 249, 252);

	/* fill colour to bottom */
	flex: 1;
}
