body {
  margin: 0;
}

h1, h2, h3 {
  font-family: Barlow, sans-serif;
}

p, input {
  font-family: Nunito Sans, sans-serif;
  font-weight: lighter;
}

span.p-highlight {
  color: #ff6e42;
  font-weight: normal;
}

.outer-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.section-outer {
  justify-content: center;
  display: flex;
}

.section-inner {
  width: 50%;
  min-width: 250px;
}

.header-bar {
  color: #f6f9fc;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-bar h1 {
  font-size: 18px;
}

.section-1 {
  color: #f6f9fc;
  padding-top: 5%;
  padding-bottom: 5%;
}

.section-1 h1 {
  font-size: 35px;
}

.section-1 p {
  font-size: 18px;
}

#section-1-body-flex {
  align-items: center;
  display: flex;
}

#section-1-body-flex > img {
  height: 150px;
  margin-left: 5vw;
}

.section-2 {
  padding-top: 3%;
}

.section-2 h3, .section-2 form {
  text-align: center;
}

.section-2 input {
  height: 40px;
  font-size: 16px;
}

.section-2 input.text-input {
  width: 200px;
  padding-left: 10px;
}

.section-2 input.submit {
  color: #fff;
  cursor: pointer;
  background-color: #ff6e42;
  border: none;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.section-2 input.submit:hover {
  background-color: #c65634;
}

.section-2 p {
  text-align: center;
  font-size: 18px;
}

.section-1, .header-bar-outer {
  background-color: #575766;
}

.section-2 {
  color: #f6f9fc;
  background-color: #303139;
  flex: 1;
}

/*# sourceMappingURL=index.dd731562.css.map */
